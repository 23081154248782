<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.editSport') }}</h5>
                <div class="p-field p-grid" v-show="this.isEdit()">
                    <label for="sportId" class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('sportsSettings.id') }}</label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" id="sportId" type="text" v-model="sport.id" disabled />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="sportName" class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('sportsSettings.name') }} *</label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" id="sportName" type="text" v-model="sport.name" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="displayName" class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('sportsSettings.displayName') }} *</label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" id="displayName" type="text" v-model="sport.displayName" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="sortOrder" class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('sportsSettings.sortOrder') }} *</label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" id="sortOrder" type="text" v-model="sport.sortOrder" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <div class="p-col-12">
                        <p>{{ $t('forms.mandatoryFieldsText') }}</p>
                    </div>
                </div>
                <transition-group name="p-message" tag="div">
                    <Message :key="errorKeyCount" :severity="'error'" v-show="showError">{{ errorText }}</Message>
                </transition-group>
                <Button @click="submitForm()" :label="$t('buttons.save')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                <Button :label="$t('buttons.cancel')" class="p-button-raised p-button-danger p-mr-2 p-mb-2" @click="goto(backRoute)" />
            </div>
        </div>
    </div>
</template>
<script>
import { SportsSettingsApi } from '../../service/SportSettingsApi';
export default {
    data() {
        return {
            SportsSettingsApi: new SportsSettingsApi(),
            sport: {},
            showError: false,
            errorText: '',
            disableSubmit: false,
            backRoute: 'sports',
            errorKeyCount: 0,
        };
    },
    mounted() {
        if (this.isEdit()) {
            this.SportsSettingsApi.getSport(this.$route.params.id)
                .then((response) => {
                    this.sport = response.data;
                })
                .catch((error) => {
                    this.errorText = error;
                    this.showError = true;
                    this.errorKeyCount++;
                });
        }
    },
    methods: {
        submitForm() {
            this.showError = false;
            this.errorText = '';
            this.disableSubmit = true;
            if (this.notNullOrEmptyObject(this.sport) && this.notNullOrEmptyField(this.sport.name) && this.notNullOrEmptyField(this.sport.displayName) && this.notNullOrEmptyField(this.sport.sortOrder)) {
                this.SportsSettingsApi.updateSport(this.sport)
                    .then(() => {
                        this.goto(this.backRoute);
                    })
                    .catch((error) => {
                        this.disableSubmit = false;
                        this.errorText = error;
                        this.showError = true;
                    });
            } else {
                this.errorText = this.$t('forms.mandatoryFieldsMessage');
                this.showError = true;
                this.disableSubmit = false;
                this.errorKeyCount++;
            }
        },
        isEdit() {
            return this.$route.params.id !== '0';
        },
    },
};
</script>
